<template>
  <div class="wrap">
    <div class="data-show">
      <p class="title"><FundTwoTone /><span>数据展示</span></p>
      <div class="card-list">
        <data-card title="用户登录数据" :card-type="1"></data-card>
        <data-card title="直播发起数据" :card-type="2"></data-card>
        <data-card title="视频上传数据" :card-type="3"></data-card>
        <data-card title="预警统计数据" :card-type="4"></data-card>
        <data-card title="飞行时长" :card-type="5"></data-card>
      </div>
    </div>
    <div class="current-live-list">
      <p class="title"><FundTwoTone /><span>当前直播列表</span></p>
      <div class="live-table">
        <a-table
          :columns="columns"
          :dataSource="tableData.value"
          :pagination="{ pageSize: pageSize, current: curPage, total: total }"
          @change="handlePageChange"
        >
          <template #action="row">
            <span class="enter-live" @click="enterLive(row)">进入直播</span>
            <span class="force-close" @click="forceClose(row)">强制关闭</span>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue';
import { FundTwoTone } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import DataCard from '../components/DataCard';
import api from '@/services';

const columns = [
  {
    title: '直播间ID',
    key: 'id',
    dataIndex: 'id',
    align: 'center'
  },
  {
    title: '直播发起人',
    key: 'nickName',
    dataIndex: 'nickName',
    align: 'center'
  },
  {
    title: '直播间名称',
    key: 'roomTitle',
    dataIndex: 'roomTitle',
    align: 'center'
  },
  {
    title: '发起时间',
    key: 'lastOnlineTime',
    dataIndex: 'lastOnlineTime',
    align: 'center'
  },
  {
    title: '当前观看人数',
    key: 'peopleNum',
    dataIndex: 'peopleNum',
    align: 'center'
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    width: 250,
    align: 'center',
    slots: {
      customRender: 'action'
    }
  }
];
export default defineComponent({
  components: {
    DataCard,
    FundTwoTone
  },

  setup() {
    onMounted(() => {
      getLiveList();
    });
    const router = useRouter();

    let tableData = reactive([]);
    let pageSize = ref(5);
    let curPage = ref(1);
    let total = ref(10);

    // 获取当前直播列表 table data
    const getLiveList = async () => {
      let params = {
        pageSize: pageSize.value,
        curPage: curPage.value
      };
      let { data, success, msg } = await api.getLiveList(params);
      if (success) {
        tableData.value = data.records;
        total.value = data.total;
      } else {
        this.message.error(msg);
      }
    };

    const handlePageChange = page => {
      curPage.value = page.current;
      getLiveList();
    };

    const enterLive = e => {
      router.push({
        path: '/live-broadcast-watch',
        query: {
          pullFlow: e.record.videoResourcesVO.pullAddressFlv,
          id: e.record.id,
          roomUserId: e.record.roomUserId,
          liveType: e.record.liveType
        }
      });
    };

    const forceClose = async row => {
      let params = {
        id: row.record.id
      };
      const success = await api.forceCloseLiveRoom(params);
      if (success) {
        message.success('强制关闭成功！');
        getLiveList();
      }
    };

    return {
      columns,
      tableData,
      pageSize,
      curPage,
      total,
      handlePageChange,
      enterLive,
      forceClose
    };
  }
});
</script>

<style lang="less" scoped>
.wrap {
  .data-show {
    .card-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .card {
        margin-bottom: 24px;
      }
    }
  }
  .data-show,
  .current-live-list {
    padding: 0 44px;
    .title {
      margin: 24px 0 12px 0;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 28px;
      span {
        margin-left: 10px;
      }
    }
    .live-table {
      ::v-deep(.ant-table) {
        td > span {
          display: inline-block;
          padding: 3px 10px;
          border-radius: 3px;
          cursor: pointer;
          font-size: 14px;
          line-height: 20px;
          color: #fff;
        }
        .enter-live {
          background-color: #007fff;
          margin-right: 14px;
        }
        .force-close {
          border: 1px solid #999;
          color: #999;
        }
      }
    }
  }
}
</style>
